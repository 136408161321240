import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import ReactTooltip from "react-tooltip";
import colors from "../styles/colors";
import * as Price from "../util/Price";
import BusinessPreview from "./BusinessPreview";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import { Flex } from "./Flex";
import Icon from "./Icon";
import { IconEnum as Icons } from "./Icons";
import { GET_ACTIVE_COUPONS, GET_REFERENCE } from "../graphql/queries";
import Button from "./Button";

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  width: 100%;
  background: ${colors.bg};
  padding-bottom: 40px;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05); */
  /* min-height: 75vw; */
  /* @media screen and (max-width: 450px) {
    padding-bottom: unset;
  } */
`;

const Header = styled.div`
  position: relative;
  background: red;
  width: 100%;
  height: fit-content;
  background: red;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  width: 100%;
  background: ${colors.bg};
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05); */
  @media screen and (max-width: 450px) {
    max-height: 70vh;
    min-height: 70vh;
    padding-bottom: unset;
  }
  @media screen and (max-height: 480px) and (max-width: 450px) {
    padding-bottom: 200px;
  }
`;

const Image = styled.div`
  background-image: ${(props) =>
    `linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  height: 120px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 1.8rem;
  color: ${colors.grey1};
  margin-bottom: 15px;
`;

const BodyText = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${colors.grey2};
`;

const Body = styled.div`
  padding: 20px 20px 100px 20px;
  text-align: center;
  background: ${colors.white};

  @media screen and (orientation: portrait) {
    /* padding: unset;
    max-height: 40vh;
    min-height: 40vh;
    overflow: auto; */
    padding: 20px 20px 250px 20px;
  }
`;

const BusinessPreviewContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 15px;
  width: 100%;
`;

const IconContainer = styled.div`
  background: ${colors.seaGreen};
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainerGrey = styled.div`
  background: ${colors.grey3};
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Selection = styled.div`
  width: fit-content;
  margin-top: 50px;
  cursor: pointer;
  width: 100%;
`;

const TextContainer = styled.div`
  text-align: left;
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
`;

const SelectionTitle = styled.div`
  font-size: 1.4rem;
  color: ${colors.grey1};
  font-weight: bold;
`;

const SelectionSubtitle = styled.div`
  margin-top: 5px;
  font-weight: 600;
  font-size: 1.4rem;
  color: ${colors.grey3};
  word-break: break-word;
  margin-right: 20px;
`;

const BackButton = styled.div`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  color: ${colors.white};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  margin: 5px;
  cursor: pointer;
`;

const TopButtonContainer = styled.div`
  position: absolute;
  height: fit-content;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-direction: row;
  position: fixed;
  background-color: ${colors.white};
  bottom: 0px;
  height: 75px;
  /* bottom: 32px; */
  align-items: center;
  max-width: 768px;

  @media screen and (max-width: 768px) and (max-height: 480px) {
    height: 75px;
    bottom: 0px;
    align-items: center;
  }
  @media screen and (max-width: 1024px) and (max-height: 480px) {
    height: 75px;
    bottom: 0px;
    align-items: center;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    bottom: 0px;
    background: ${colors.white};
    height: max-content;
  }
  @media screen and (max-height: 480px) and (max-width: 450px) {
    bottom: 0px;
    padding-bottom: 20px;
    height: 8vh;
  }
  @media screen and (max-height: 480px) and (max-width: 320px) {
    height: 75px;
  } ;
`;

// looks hacky but is a more browser compatible way of copying text
// as opposed to navigator.clipboard.writeText()
function copyToClipboard(text) {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

function InviteFriends() {
  const history = useHistory();
  const [tooltipRef, setTooltipRef] = useState(null);
  const { organization } = useContext(OrganizationContext);
  // TODO: refactor and clean up
  const { data: coupData, loading: coupLoading } = useQuery(
    GET_ACTIVE_COUPONS,
    {
      variables: {
        organizationId: organization?._id,
      },
    }
  );

  const { data, loading } = useQuery(GET_REFERENCE, {
    variables: {
      organizationId: organization?._id,
    },
  });

  if (!organization || loading || coupLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
  const isIOS =
    navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const inviteLink = data?.getReference?._id
    ? `${window.location.origin}/user/invite/?ref=${data?.getReference?._id}`
    : "";
  const c = organization?.configurations;
  //   const e = expireData?.getCouponExpiration;
  const first = Price.output(c?.firstTimeRecipientCredit, true);
  const repeat = Price.output(c?.repeatRecipientCredit, true);
  const affiliate = Price.output(c?.affiliateCredit, true);

  // issue with & (and potentially others) being a delimiter and truncating text when injecting into native texting apps
  const presetMessage = `Hi, I had a great experience at ${organization.name} and I think you should check it out too. Redeem this discount for up to $${first} when you make a purchase at ${organization.name}, we'll both get a discount! Click here to activate your discount: ${inviteLink}`;

  return (
    <OuterContainer>
      <Container>
        <Header>
          <Image src={organization.posterImageUrl} />
          <TopButtonContainer>
            <BackButton onClick={() => history.push("/organizations")}>
              <Icon
                icon={Icons.LeftArrow}
                activeColor={colors.white}
                active
                size={14}
                margin="0px 10px 0px 0px"
              />
              <div>GO BACK</div>
            </BackButton>
            {/* {coupData?.activeCoupons?.length > 0 && (
              <BackButton>
                <Icon
                  icon={Icons.DollarSign}
                  activeColor={colors.white}
                  active
                  size={14}
                  margin="0px 10px 0px 0px"
                />

                {
                  <div
                    onClick={() =>
                      history.push(`/discounts/?orgId=${organization._id}`)
                    }
                  >
                    Discounts!
                  </div>
                }
              </BackButton>
            )} */}
          </TopButtonContainer>
          <BusinessPreviewContainer>
            <BusinessPreview organization={organization} />
          </BusinessPreviewContainer>
        </Header>
        <InnerContainer>
          {inviteLink && (
            <Body>
              <HeaderText>Invite Friends</HeaderText>

              <BodyText>
                {first === repeat
                  ? `Send friends $${first} and earn $${affiliate}
          when they redeem their discounts at ${organization.name}`
                  : `Send friends $${first} if they haven't been to ${organization.name} or
          $${repeat} if they have and earn $${affiliate}
          when they redeem their discount. Discount redemption is limited to one per table.`}
              </BodyText>
              <>
                {" "}
                <a
                  href={
                    isIOS
                      ? `sms:;?&body=${presetMessage}`
                      : `sms:;?&body=${presetMessage}`
                  }
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <Selection>
                    <Flex align="center" justify="center" width="100%">
                      <IconContainer>
                        <Icon
                          icon={Icons.CommentSolid}
                          activeColor={colors.white}
                          active
                        />
                      </IconContainer>
                      <TextContainer>
                        <Button
                          text="Invite Friends Now"
                          width="70%"
                          xsWidth="100%"
                          xsHeight="40px"
                        />
                      </TextContainer>
                    </Flex>
                  </Selection>
                </a>
                <Selection
                  onClick={() => {
                    copyToClipboard(inviteLink);
                    ReactTooltip.show(tooltipRef);
                    setTimeout(() => {
                      ReactTooltip.hide(tooltipRef);
                    }, 3000);
                  }}
                >
                  <Flex
                    align="center"
                    justify="center"
                    ref={setTooltipRef}
                    data-tip="Link copied to clipboard"
                    data-event="null"
                  >
                    <IconContainer>
                      <Icon
                        icon={Icons.LinkSolid}
                        activeColor={colors.white}
                        active
                      />
                    </IconContainer>
                    <TextContainer>
                      <SelectionTitle>Copy your Invite Link</SelectionTitle>
                      <SelectionSubtitle>{inviteLink}</SelectionSubtitle>
                    </TextContainer>
                  </Flex>
                </Selection>
                {coupData?.activeCoupons?.length > 0 && (
                  <Selection onClick={() => history.push(`/discounts/?orgId=${organization?._id}`)}>
                    <Flex
                      align="center"
                      justify="center"
                      width="100%"
                    >
                      <IconContainerGrey>
                        <Icon
                          icon={Icons.DollarSign}
                          activeColor={colors.white}
                          active
                        />
                      </IconContainerGrey>
                      <TextContainer>
                        <Button
                          text="View Your Discounts!"
                          background={colors.grey3}
                          width="70%"
                          xsWidth="100%"
                          xsHeight="40px"
                        />
                      </TextContainer>
                    </Flex>
                  </Selection>
                )}
              </>
            </Body>
          )}
        </InnerContainer>
        <ReactTooltip place="bottom" type="dark" effect="solid" />
        {(c?.websiteUrl || c?.reservationUrl) && (
          <ButtonContainer>
            {c?.reservationUrl && (
              <Button
                margin="15px 0 15px 0"
                xsMargin="5px"
                background={colors.grey3}
                text="MAKE RESERVATION"
                type="button"
                onClick={() =>
                  window.open(
                    c.shortReservationUrl || c?.reservationUrl,
                    "_blank"
                  )
                }
                width="40%"
                xsWidth="70%"
                xsHeight="35px"
              />
            )}
            {c?.websiteUrl && (
              <Button
                background={colors.grey3}
                margin="15px 0 15px 0"
                xsMargin="5px"
                text="VISIT WEBSITE"
                type="button"
                width="40%"
                onClick={() =>
                  window.open(c.shortWebsiteUrl || c?.websiteUrl, "_blank")
                }
                xsWidth="70%"
                xsHeight="35px"
              />
            )}
          </ButtonContainer>
        )}
      </Container>

    </OuterContainer>
  );
}

export default InviteFriends;
