/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { Route, withRouter, useHistory } from "react-router-dom";
import url from "url";
import colors from "../styles/colors";
import { GET_ORGANIZATION } from "../graphql/queries";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";

const LayoutContainer = styled.div`
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

// desktop will be in mobile type view for mvp
const ViewContainer = styled.div`
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;
  max-width: 768px;
`;

function Layout({ location, component: Component, ...rest }) {
  const history = useHistory();
  const { query: { orgId } } = url.parse(window.location.toString(), true);
  const { data, loading, error } = useQuery(GET_ORGANIZATION, {
    variables: {
      organizationId: orgId
    },
    onError(error) {
      console.error(error);
    }
  });

  const { setOrganization } = useContext(OrganizationContext);
  useEffect(() => {
    if (data && data.getOrganization) {
      setOrganization(data.getOrganization);
    }
  }, [data, setOrganization, history]);

  if (loading || error || !data?.getOrganization) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }



  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LayoutContainer>
          <ViewContainer>
            <Component {...matchProps} />
          </ViewContainer>
        </LayoutContainer>
      )}
    />
  );
}

export default withRouter(Layout);
