/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import { Route, withRouter, Redirect, useHistory } from "react-router-dom";
import url from "url";
import colors from "../styles/colors";
import { GET_ORGANIZATION, GET_REFERENCE_BY_ID } from "../graphql/queries";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";

const LayoutContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const EmbedLayoutContainer = styled.div`
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

// desktop will be in mobile type view for mvp
const ViewContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;
  max-width: 768px;
`;

const EmbedViewContainer = styled.div`
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;
  max-width: 768px;
`;

function Layout({ location, component: Component, ...rest }) {

  const history = useHistory();
  const { setOrganization } = useContext(OrganizationContext);

  const {
    query: { orgId, ref, o },
  } = url.parse(window.location.toString(), true);
  const isEmbedRoute = location?.pathname === '/embed/'


  const {
    data: refData,
    loading: refLoading,
    error: refError,
  } = useQuery(GET_REFERENCE_BY_ID, {
    variables: {
      referenceId: ref,
    },
    skip: !ref,
  });
  const organizationId = refData?.getReferenceById?.organizationId || orgId || o
  const { data, loading, error } = useQuery(GET_ORGANIZATION, {
    variables: {
      organizationId,
    },
    skip: !(organizationId),
  });


  useEffect(() => {
    if (data && data.getOrganization) {
      setOrganization(data.getOrganization);
      if (!data.getOrganization.activeSubscriber)
        history.push(`/unsubscribed?orgId=${data?.getOrganization?._id}`);
    }
  }, [data, setOrganization, history]);


  if (loading || refLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (error || (ref && refError)) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        !isEmbedRoute ?
          <LayoutContainer>
            <ViewContainer>
              <Component {...matchProps} />
            </ViewContainer>
          </LayoutContainer>
          :
          <EmbedLayoutContainer>
            <EmbedViewContainer>
              <Component {...matchProps} />
            </EmbedViewContainer>
          </EmbedLayoutContainer>
      )}
    />
  );
}

export default withRouter(Layout);
