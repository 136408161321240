import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import url from "url";
import colors from "../styles/colors";
import Button from "./Button";
import BusinessPreview from "./BusinessPreview";
import { PhoneNumberInput } from "./Input";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import {
	SEND_PHONE_CODE,
	VERIFY_PHONE_CODE,
	CREATE_REFERENCE,
	CREATE_COUPON,
} from "../graphql/queries";
import CodeInputBoxes from "./CodeInputBoxes";
import Error from "./Error";
import { getErrorMessage } from "../util/ErrorUtil";
import CouponParentTypes from "../util/CouponParentTypes";
import { AppContext } from "../context/AppContext";
import { originalSources } from "../util/contactConstants";

const LoaderContainer = styled.div`
	min-height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Container = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	min-height: 80vh;
	width: 100%;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
	background: ${colors.bg};
	position: relative;
`;

const Header = styled.div`
	position: relative;
	background: red;
	width: 100%;
	height: fit-content;
	background: red;
	box-sizing: border-box;
`;

const Image = styled.div`
	background-image: ${(props) =>
		`linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
	background-size: cover;
	background-position: center;
	background-origin: unset;
	height: 120px;
	width: 100%;
`;

const HeaderText = styled.div`
	font-size: 1.8rem;
	font-weight: bold;
	color: ${colors.grey1};
	margin-bottom: 5px;
`;

const BodyText = styled.div`
	font-weight: 500;
	font-size: 1.4rem;
	color: ${colors.grey2};
	margin-bottom: 15px;
`;

const CodeText = styled.div`
	font-weight: 500;
	font-size: 1.4rem;
	color: ${colors.grey2};
	margin: 15px 0px;
`;

const Body = styled.div`
	flex: 1;
	margin: 40px 15px;
`;

const BusinessPreviewContainer = styled.div`
	position: absolute;
	bottom: -25px;
	left: 15px;
	width: 100%;
`;

const OuterContainer = styled.form`
	height: 100vh;
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	flex-direction: row;
	position: absolute;
	background-color: ${colors.white};
	bottom: 0px;
	min-height: 75px;
	bottom: 32px;
	align-items: center;
	max-width: 768px;

	@media screen and (max-width: 768px) {
		min-height: 70px;
		bottom: 0px;
		align-items: center;
	}
	@media screen and (max-width: 1024px) {
		min-height: 100px;
		bottom: 0px;
		align-items: center;
	}

	@media screen and (max-width: 450px) {
		flex-direction: column;
		align-items: center;
		bottom: 0px;
		background: ${colors.white};
		height: max-content;
	} ;
`;

function AcceptCoupon() {
	const history = useHistory();
	const { organization } = useContext(OrganizationContext);
	const { setApp } = useContext(AppContext);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [error, setError] = useState("");
	const [codeInputIsVisible, setCodeInputIsVisible] = useState(false);
	const [sendPhoneCode, { loading }] = useMutation(SEND_PHONE_CODE, {
		variables: {
			organizationId: organization._id,
			phoneNumber,
			originalSource: originalSources.REF,
		},
		onCompleted(data) {
			setCodeInputIsVisible(true);
		},
		onError(error) {
			setError(getErrorMessage(error));
			console.log(error);
		},
	});
	const [createReference, { loading: refLoading }] = useMutation(
		CREATE_REFERENCE,
		{
			async onCompleted(data) {
				history.push(`/inviteFriends/?orgId=${organization._id}`);
			},
			onError(error) {
				setError(getErrorMessage(error));
				console.log(error);
			},
		},
	);
	const [createCoupon, { loading: couponLoading }] = useMutation(
		CREATE_COUPON,
		{
			async onCompleted(data) {
				await createReference({
					variables: {
						organizationId: organization._id,
					},
				});
			},
			onError(error) {
				setApp({ error: getErrorMessage(error) });
				history.push(`/inviteError/?orgId=${organization._id}`);
				console.log(error);
			},
		},
	);
	const [verifyPhoneCode, { loading: verifyLoading }] = useMutation(
		VERIFY_PHONE_CODE,
		{
			async onCompleted(data) {
				if (data.verifyPhoneCode.token) {
					localStorage.setItem(
						"CHECK_THIS_OUT_AUTH_TOKEN",
						data.verifyPhoneCode.token,
					);
					
					const {
						query: { ref },
					} = url.parse(window.location.toString(), true);
					await createCoupon({
						variables: {
							referenceId: ref,
							couponParentType: CouponParentTypes.Referred,
						},
					});
				} else {
					setError("There was an error. Please contact support.");
				}
			},
			onError(error) {
				setError(getErrorMessage(error));
				console.log(error);
			},
		},
	);

	// not 100% sure why organization return null on 1st render
	if (!organization || refLoading || verifyLoading || couponLoading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}

	return (
		<OuterContainer 	onSubmit={(e) => {
      e.preventDefault();
      sendPhoneCode();
    }}>
			<Container>
				<Header>
					<Image src={organization.posterImageUrl} />
					<BusinessPreviewContainer>
						<BusinessPreview organization={organization} />
					</BusinessPreviewContainer>
				</Header>
				<Body>
					<HeaderText>Please enter your phone number</HeaderText>
					<BodyText>We need it to verify who you are</BodyText>
					<PhoneNumberInput
						type="tel"
						placeholder="Enter your phone number"
						value={phoneNumber}
						onChange={(e) => {
							setPhoneNumber(e.target.value);
							if (codeInputIsVisible) {
								setCodeInputIsVisible(false);
							}
						}}
						width="calc(100% - 30px)"
						required
					/>
					{codeInputIsVisible && (
						<>
							<CodeText>
								We just sent a 4-digit code to your mobile phone, please
								enter it below.
							</CodeText>
							<CodeInputBoxes
								onComplete={(phoneCode) => {
									verifyPhoneCode({
										variables: {
											phoneNumber,
											phoneCode,
											organizationId: organization._id,
										},
									});
								}}
							/>
						</>
					)}
					{error && (
						<Error error={error} margin="20px 0px 0px 0px">
							{error}
						</Error>
					)}
				</Body>
			</Container>
			{!codeInputIsVisible && (
				<ButtonContainer>
					<Button
						margin="15px"
						text="CONFIRM PHONE NUMBER"
						width="calc(100% - 30px)"
						loading={loading}
					/>
				</ButtonContainer>
			)}
		</OuterContainer>
	);
}

export default AcceptCoupon;
