import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import colors from "../styles/colors";
import Button from "./Button";
import { PhoneNumberInput } from "./Input";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import { SEND_PHONE_CODE, VERIFY_PHONE_CODE_EMBED } from "../graphql/queries";
import CodeInputBoxes from "./CodeInputBoxes";
import { getErrorMessage } from "../util/ErrorUtil";
import { originalSources } from "../util/contactConstants";

const DefaultColors = Object.freeze({
  backgroundColor: "#0f233c",
  buttonTextColor: "#FFFFFF",
  buttonColor: colors.seaGreen,
});

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OuterContainer = styled.div`
  width: 100%;
  font-family: "Montserrat", Helvetica;
  background: ${(props) => props.background || DefaultColors.backgroundColor};
  padding: 0px 30px;
  @media only screen and (min-width: 200px) and (max-width: 425px)  {
    padding: 0px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${colors.bg};
  position: relative;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px 25px 0px;
  @media only screen and (min-width: 200px) and (max-width: 767px)  {
    padding: ${(props) => props.successMsg ? "30px 0px 30px 0px" : "30px 0px 15px 0px"};
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: ${colors.white};
  border-radius: 5px;
  align-items: center;
`;

const FormDiv = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  padding: 10px 20px 0px 20px;
  background: ${(props) => props.background || DefaultColors.backgroundColor};
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

const HeadingText = styled.div`
  font-size: clamp(0px, 9vw, 300px);
  font-weight: bold;
  color: ${(props) => props.color || DefaultColors.buttonTextColor};
  margin-bottom: 5px;
  @media screen and (max-width: 450px) {
    padding: 12px 20px 0px 20px;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    font-size: 40px;
  }
  @supports (-webkit-touch-callout: none) {
    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
`;

const SubHeadingText = styled.div`
  font-size: clamp(0px, 10vw, 1.5rem);
  color: ${(props) => props.color || DefaultColors.buttonTextColor};
  text-align: center;
  padding: 0 10px;
  padding-bottom: 10px;
  word-break: break-word;
`;

const CodeText = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${colors.white};
  padding-bottom: 5px;
`;
const CodeBoxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CloseContiner = styled.div`
  position: fixed;
  cursor: pointer;
  top: 6px;
  right: 8px;
  display: inline-block;
  box-sizing:border-box;
  width:20px;
  height:20px;
  border-width: 3px;
  border-style: solid;
  border-color: ${(props) => props.background || DefaultColors.buttonColor};
  border-radius: 100%;
  background-color: ${(props) => props.background || DefaultColors.buttonColor};;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 600;
  z-index: 999999;
  color: ${(props) => props.color || DefaultColors.buttonTextColor};
`

const Terms = styled.p`
  text-align: center;
  color: white;
  font-size: 1rem;
  @supports (-webkit-touch-callout: none) {
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ErrorContainer = styled.div`
  color: ${colors.red};
  fontSize: 12px;
`

const DefaultText = {
  headline: "Become a VIP",
  introductoryText: "Be the first to know about gift card giveaways, specials, exclusive events, and more!",
}

const successMsg = "Thank you for enjoying your experience at {OrgName} and for joining our VIP list.  We'll keep you informed of our latest specials, events, and giveaways, and we promise to keep the texts enjoyable and to-the-point.  We look forward to seeing you at {OrgName} again soon!"

function Embed() {
  const { organization } = useContext(OrganizationContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [codeInputIsVisible, setCodeInputIsVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const getWindowsDimensions = () => {
    const width = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight ||
      document.body.clientHeight;
    const contentHeight = document?.getElementById("CTO_PORTAL_CONTAINER").offsetHeight
    const orientation = (window?.screen.orientation || {}).type || window?.screen.mozOrientation || window?.screen.msOrientation;
    return {
      height,
      width,
      contentHeight,
      screenWidth: window?.screen?.width || 200,
      screenHeight: window?.screen?.height || 200,
      availHeight: window?.screen?.availHeight || 200,
      availWidth: window?.screen?.availWidth || 200,
      orientation
    }
  }

  const updateFrameDimensions = () => {
    const data = getWindowsDimensions()
    window.parent.postMessage({
      action: "RESIZE_IFRAME", args: data
    }, "*")
  }

  useEffect(() => {
    if (organization) {
      window.parent.postMessage({ action: "SET_STATUS", args: { status: "ACTIVE" } }, "*")
      updateFrameDimensions()
    }
  }, [organization]);

  const [sendPhoneCode, { loading }] = useMutation(SEND_PHONE_CODE, {
    variables: {
      organizationId: organization?._id,
      phoneNumber,
      originalSource: originalSources.MODAL,
    },
    onCompleted(data) {
      setCodeInputIsVisible(true);
      updateFrameDimensions()
    },
    onError(error) {
      setError(getErrorMessage(error));
      console.log(error);
    },
  });

  const [verifyPhoneCode, { loading: verifyLoading }] = useMutation(
    VERIFY_PHONE_CODE_EMBED,
    {
      async onCompleted(data) {
        setShowSuccessMessage(data?.verifyPhoneCodeEmbed);
        setCodeInputIsVisible(false);
        updateFrameDimensions()
      },
      onError(error) {
        setError(getErrorMessage(error));
        console.log(error);
      },
    }
  );

  const close = () => {
    window.parent.postMessage({ action: "CLOSE_WINDOW" }, "*");
    window.parent.postMessage({ action: "SET_STATUS", args: { status: "CLOSED" } }, "*");
  }

  if (!organization || verifyLoading) {
    return (
      <LoaderContainer id="CTO_PORTAL_CONTAINER">
        <Loader />
      </LoaderContainer>
    );
  }

  const bgColor = organization?.integration?.backgroundColor || DefaultColors.backgroundColor
  const btnTextColor = organization?.integration?.buttonTextColor || DefaultColors.buttonTextColor
  const btnColor = organization?.integration?.buttonColor || DefaultColors.buttonColor

  return (
    <OuterContainer background={bgColor} id="CTO_PORTAL_CONTAINER">
      <CloseContiner
        onClick={close}
        color={btnTextColor}
        background={btnColor}
      ><span>x</span></CloseContiner>
      <Container>
        <>
          <Content successMsg={showSuccessMessage}>
            <HeaderDiv background={bgColor} >
              <HeadingText color={bgColor}>
                {showSuccessMessage ? "SUCCESS!" : organization?.integration?.headline || DefaultText.headline}
              </HeadingText>
              <SubHeadingText color={bgColor}>
                {
                  showSuccessMessage
                    ? successMsg.replaceAll('{OrgName}', organization?.name)
                    : organization?.integration?.introductoryText || DefaultText.introductoryText
                }
              </SubHeadingText>
            </HeaderDiv>
            {!showSuccessMessage && (
              <>
                <FormDiv
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendPhoneCode();
                  }}
                  background={bgColor}
                >
                  {!codeInputIsVisible && (
                    <>
                      <PhoneNumberInput
                        padding="0"
                        width="68%"
                        xsWidth="100%"
                        xsHeight="40px"
                        placeholder="Phone Number"
                        placeholderColor={bgColor}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                      <Button
                        type="submit"
                        xsWidth="100%"
                        width="30%"
                        text="Sign Up"
                        radius="25px"
                        xsMargin="10px 0 0 0"
                        loading={loading}
                        textColor={btnTextColor}
                        background={btnColor}
                      />
                    </>
                  )}
                  {codeInputIsVisible && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CodeText>
                        We just sent a 4-digit code to your mobile phone, please enter it below.
                      </CodeText>
                      <CodeBoxContainer>
                        <CodeInputBoxes
                          height="30px"
                          width="30px"
                          xsWidth="35px"
                          onComplete={(phoneCode) => {
                            verifyPhoneCode({
                              variables: {
                                phoneNumber,
                                phoneCode,
                                organizationId: organization._id,
                              },
                            });
                          }}
                        />
                      </CodeBoxContainer>
                    </div>
                  )}
                </FormDiv>
                {error && (
                  <ErrorContainer>{error}</ErrorContainer>
                )}
                <Terms>
                  By providing your phone number, you agree to participate in
                  the marketing program and to be contacted through this phone
                  number as part of the program. This program information is subject
                  to Check This Out's&nbsp;
                  <a
                    href="https://intercom.help/check-this-out/en/articles/4339634-check-this-out-terms-of-use-and-privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: btnColor,
                    }}
                  >
                    Terms of Use and Privacy Policy
                  </a>
                </Terms>
              </>
            )}
          </Content>
        </>
      </Container>
    </OuterContainer>
  );
}

export default Embed;
