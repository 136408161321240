import React, { Component } from "react";
import styled from "styled-components";
import colors from "../styles/colors";

const Container = styled.div`
  margin: ${(props) => props.margin};
`;

const Input = styled.input`
  border: 2px solid ${colors.grey5};
  height: ${(props) => props.height || "70px"};
  width: ${(props) => props.width || "50px"};
  border-radius: 5px;
  background-color: white;
  transition: all 0.1s;
  margin-right: 10px;
  outline: 0px;
  font-size: 24px;
  text-align: center;
  padding: 0px;
  box-shadow: none;
  -moz-appearance: textfield;
  @media (max-width: 320px) {
    width: 25px;
  }
  @media (min-width: 425px) and (max-width: 1024px) {
    width: ${(props) => props.xsWidth || "35px"};
  }
  &:focus {
    border: 2px solid ${colors.purple};
  }
`;

export default class CodeInputBoxes extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    length: 4,
    onChange: () => {},
    onComplete: () => {},
  };

  constructor(params) {
    super(params);
    this.state = {
      value: new Array(this.props.length).fill(""),
      lastValue: new Array(this.props.length).fill(""),
    };

    this.inputs = [];
  }

  onChange(valueAt, index) {
    const currentValue = [...this.state.value];
    const currentValueSaved = currentValue;

    // Single Character Press
    if (valueAt.length === 1) {
      currentValue[index] = valueAt;
      this.setState({
        value: currentValue,
        lastValue: currentValueSaved,
      });
      if (this.inputs[index + 1]) {
        this.inputs[index + 1].focus();
      }
    } else if (valueAt.length === this.props.length) {
      //code pasted
      this.setState({
        value: valueAt.split(""),
        lastValue: currentValue,
      });
      this.props.onChange(valueAt);
      this.props.onComplete(valueAt);
      if (this.inputs[index + 1]) {
        this.inputs[index + 1].focus();
      }
    } else if (valueAt.length > 0 && valueAt.length < this.props.length) {
      // More than 1 character, less than the total number required
      this.onChange(valueAt.charAt(valueAt.length - 1), index);
    }

    if (index + 1 === this.props.length) {
      this.props.onComplete(currentValue.join(""));
    }
  }

  renderInput(index) {
    const value = this.state.value[index];
    return (
      <Input
        key={index}
        autoFocus={index === 0}
        height={this.props.height}
        width={this.props.width}
        required
        value={value}
        type="number"
        pattern="\d*"
        ref={(ref) => (this.inputs[index] = ref)}
        onChange={(event) => this.onChange(event.target.value, index)}
        onKeyDown={(event) => {
          if (event.keyCode === 8 || event.keyCode === 46) {
            // handle backspace or delete
            event.preventDefault();
            const currentValue = [...this.state.value];
            const currentValueSaved = currentValue;
            currentValue[index] = "";
            this.setState({
              value: currentValue,
              lastValue: currentValueSaved,
            });
            if (index !== 0) {
              this.inputs[index - 1].focus();
            }
          } else if (event.keyCode === 37) {
            // navigate left with left arrow key
            event.preventDefault();
            if (index !== 0) {
              this.inputs[index - 1].focus();
            }
          } else if (event.keyCode === 39) {
            // navigate right with right arrow key
            event.preventDefault();
            if (index !== this.state.value.length - 1) {
              this.inputs[index + 1].focus();
            }
          } else if (
            // prohibit weird edge behavior when up, down, +, or - are pressed
            event.keyCode === 38 ||
            event.keyCode === 40 ||
            event.keyCode === 107 ||
            event.keyCode === 109
          ) {
            event.preventDefault();
          }
        }}
      />
    );
  }

  render() {
    return (
      <Container>
        {this.state.value.map((_, index) => {
          return this.renderInput(index);
        })}
      </Container>
    );
  }
}
