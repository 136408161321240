/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import colors from '../styles/colors';

const LayoutContainer = styled.div`
  /* height: ${`${window.innerHeight}px`}; */
  width: 100vw;
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ViewContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 100%;
  max-width: 768px;
  /* padding: 15px; */
  padding: 5px;
  box-sizing: border-box;
`;

export default function HomeLayout({ location, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LayoutContainer>
          <ViewContainer>
            <Component {...matchProps} />
          </ViewContainer>
        </LayoutContainer>
      )}
    />
  );
}
