import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import url from "url";
import colors from "../styles/colors";
import Button from "./Button";
import BusinessPreview from "./BusinessPreview";
import * as Price from "../util/Price";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import { PhoneNumberInput } from "./Input";
import CodeInputBoxes from "./CodeInputBoxes";
import Error from "./Error";
import { originalSources } from "../util/contactConstants";
import {
  CREATE_COUPON,
  CREATE_REFERENCE,
  SEND_PHONE_CODE,
  UPDATE_REF_CLICK_COUNT,
  VERIFY_PHONE_CODE,
} from "../graphql/queries";
import { useMutation } from "@apollo/react-hooks";
import { getErrorMessage } from "../util/ErrorUtil";
import { AppContext } from "../context/AppContext";
import CouponParentTypes from "../util/CouponParentTypes";

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.bg};
`;

const Header = styled.div`
  position: relative;
  background: red;
  width: 100%;
  height: fit-content;
  background: red;
  box-sizing: border-box;
`;

const CodeText = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${colors.grey2};
  margin: 30px 0px 15px 0px;
`;

const Image = styled.div`
  background-image: ${(props) =>
    `linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  height: 200px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${colors.grey1};
  line-height: 140%;
  margin-bottom: 15px;
`;

const BodyText = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 140%;
  color: ${colors.grey2};
  margin-bottom: 15px;
`;

const Body = styled.div`
  flex: 1;
  margin: 40px 15px;
`;

const BusinessPreviewContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 15px;
  width: 100%;
`;

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const TOS = styled.div`
  color: ${colors.grey3};
  margin: 25px 15px 10px 15px;
  text-align: center;
`;

const TOSLink = styled.a`
  color: ${colors.seaGreen};
`;

/**
 * This function handles invites from both an organization
 * and a referrer
 */
function Invite() {
  const history = useHistory();
  const { organization } = useContext(OrganizationContext);
  const { setApp } = useContext(AppContext);
  const {
    query: { ref },
  } = url.parse(window.location.toString(), true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [codeInputIsVisible, setCodeInputIsVisible] = useState(false);
  const [originalSource, setOriginalSource] = useState(originalSources.ORG);
  const { query } = url.parse(window.location.toString(), true);

  useEffect(() => {
    function updateOriginalSource() {
      if (query.ref) {
        setOriginalSource(originalSources.REF);
      } else if (query.orgId) {
        setOriginalSource(originalSources.ORG);
      } else if (query.o) {
        setOriginalSource(originalSources.SMS);
      }
    }
    updateOriginalSource();
  }, [query]);

  const [sendPhoneCode, { loading }] = useMutation(SEND_PHONE_CODE, {
    variables: {
      organizationId: organization?._id,
      phoneNumber,
      originalSource,
    },
    onCompleted(data) {
      setCodeInputIsVisible(true);
    },
    onError(error) {
      setError(getErrorMessage(error));
      console.log(error);
    },
  });
  const [createReference, { loading: refLoading }] = useMutation(
    CREATE_REFERENCE,
    {
      async onCompleted(data) {
        history.push(`/inviteFriends/?orgId=${organization?._id}`);
      },
      onError(error) {
        setError(getErrorMessage(error));
        console.log(error);
      },
    }
  );

  const [createCoupon, { loading: couponLoading }] = useMutation(
    CREATE_COUPON,
    {
      async onCompleted(data) {
        await createReference({
          variables: {
            organizationId: organization?._id,
          },
        });
      },
      onError(error) {
        setApp({ error: getErrorMessage(error) });
        history.push(`/inviteError/?orgId=${organization?._id}`);
        console.log(error);
      },
    }
  );

  const [verifyPhoneCode, { loading: verifyLoading }] = useMutation(
    VERIFY_PHONE_CODE,
    {
      async onCompleted(data) {
        if (data.verifyPhoneCode.token) {
          localStorage.setItem(
            "CHECK_THIS_OUT_AUTH_TOKEN",
            data.verifyPhoneCode.token
          );

          if (ref) {
            await createCoupon({
              variables: {
                referenceId: ref,
                couponParentType: CouponParentTypes.Referred,
              },
            });
          } else {
            await createReference({
              variables: {
                organizationId: organization._id,
              },
            });
          }
        } else {
          setError("There was an error. Please contact support.");
        }
      },
      onError(error) {
        setError(getErrorMessage(error));
        console.log(error);
      },
    }
  );

  const [updateClickCount] = useMutation(UPDATE_REF_CLICK_COUNT, {
    variables: {
      referenceId: ref,
    },
  });

  useEffect(() => {
    if (ref) updateClickCount();
  }, []); // eslint-disable-line

  // not 100% sure why organization return null on 1st render
  if (!organization || refLoading || verifyLoading || couponLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
  const c = organization.configurations;

  const first = Price.output(c.firstTimeRecipientCredit, true);
  const repeat = Price.output(c.repeatRecipientCredit, true);
  const affiliate = Price.output(c.affiliateCredit, true);

  return (
    <OuterContainer>
      <Container>
        <Header>
          <Image src={organization.posterImageUrl} />
          <BusinessPreviewContainer>
            <BusinessPreview organization={organization} />
          </BusinessPreviewContainer>
        </Header>
        <Body>
          <HeaderText>
            {ref
              ? `Your friend is sending you a cash gift to ${organization.name}`
              : first === repeat
              ? `Send $${first}
               to a friend and
               get $${affiliate}
               for yourself when they redeem their discount.`
              : `Send a discount to a friend and get $${affiliate} for yourself. Enter your phone number below to get started.`}
          </HeaderText>
          <BodyText>
            {ref
              ? "Hit the button below to verify your phone number and accept your discount."
              : `You and your friend will earn a discount $${affiliate} to ${organization.name} for every single customer you refer. No limits on the number of invitations. Referral credits can be combined and credits don’t expire.`}
           
          </BodyText>

          <BodyText>
            Please enter your phone number. We need it to verify who you are
          </BodyText>

          <PhoneNumberInput
            type="tel"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              if (codeInputIsVisible) {
                setCodeInputIsVisible(false);
              }
              if (error) setError("");
            }}
            width="calc(100% - 30px)"
            required
          />

          {codeInputIsVisible && (
            <>
              <CodeText>
                We just sent a 4-digit code to your mobile phone, please enter
                it below.
              </CodeText>
              <CodeInputBoxes
                onComplete={(phoneCode) => {
                  if (error) setError("");
                  verifyPhoneCode({
                    variables: {
                      phoneNumber,
                      phoneCode,
                      organizationId: organization?._id,
                    },
                  });
                }}
              />
            </>
          )}
          {error && (
            <Error error={error} margin="20px 0px 0px 0px">
              {error}
            </Error>
          )}
        </Body>
        <TOS>
          By using Check This Out, you agree to the &nbsp;
          <TOSLink
            href="https://intercom.help/check-this-out/en/articles/4339634-check-this-out-terms-of-use-and-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use and Privacy Policy
          </TOSLink>
        </TOS>
        {!codeInputIsVisible && (
          <Button
            margin="0px 15px 15px 15px"
            text={ref ? "ACCEPT GIFT" : "CONFIRM PHONE NUMBER"}
            width="calc(100% - 30px)"
            loading={loading}
            onClick={() => sendPhoneCode()}
          />
        )}
      </Container>
    </OuterContainer>
  );
}

export default Invite;
