import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import colors from "../styles/colors";
import Loader from "./Loader";
import Button from "./Button";
import { PhoneNumberInput } from "./Input";
import CodeInputBoxes from "./CodeInputBoxes";
import Error from "./Error";
import { getErrorMessage } from "../util/ErrorUtil";
import { SEND_PHONE_CODE, VERIFY_PHONE_CODE } from "../graphql/queries";
import Logo from "../assets/images/cto-full-logo.png";

const LoaderContainer = styled.div`
	min-height: 95vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Container = styled.form`
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	width: 100%;
	display: flex;
	flex-direction: column;
	max-height: 95vh;
	min-height: 95vh;
	position: relative;
	@media screen and (max-height: 480px) {
		max-height: 92vh;
		min-height: 92vh;
		padding-bottom: unset;
	} ;
`;

const BodyText = styled.div`
	margin-top: 15px;
	margin-bottom: 15px;
	font-weight: 600;
	font-size: 1.8rem;
	color: ${colors.grey1};
	max-height: 70vh;
	overflow-y: auto;
`;

const BottomText = styled.div`
	margin-top: 15px;
	margin-bottom: 15px;
	font-weight: 500;
	font-size: 1.4rem;
	color: ${colors.grey4};
`;

const CodeText = styled.div`
	font-weight: 500;
	font-size: 1.4rem;
	color: ${colors.grey2};
	margin: 30px 0px 15px 0px;
`;

const Body = styled.div`
	margin: 0 15px 10px 15px;
	padding-bottom: 60px;
	overflow-y: auto;
	position: relative;
`;

const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
	margin-top: 40px;
`;

const LogoImg = styled.img`
	width: 120px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
  z-index:10;
	flex-direction: row;
	position: absolute;
	background-color: ${colors.white};
	bottom: 0px;
	align-items: center;
	max-width: 768px;

	@media screen and (max-width: 768px) {
		min-height: 70px;
		bottom: 0px;
		align-items: center;
	}
	@media screen and (max-width: 1024px) {
		bottom: 0px;
		align-items: center;
	}

	@media screen and (max-width: 450px) {
		flex-direction: column;
		align-items: center;
		bottom: 0px;
		background: ${colors.white};
		height: max-content;
	} ;
`;

export default function Home() {
	const history = useHistory();
	const [phoneNumber, setPhoneNumber] = useState("");
	const [error, setError] = useState("");
	const [codeInputIsVisible, setCodeInputIsVisible] = useState(false);
	const [sendPhoneCode, { loading }] = useMutation(SEND_PHONE_CODE, {
		variables: {
			phoneNumber,
		},
		onCompleted(data) {
			setCodeInputIsVisible(true);
		},
		onError(error) {
			setError(getErrorMessage(error));
			console.log(error);
		},
	});
	const [verifyPhoneCode, { loading: verifyLoading }] = useMutation(
		VERIFY_PHONE_CODE,
		{
			async onCompleted(data) {
				if (data.verifyPhoneCode.token) {
					if (error) setError("");
					localStorage.setItem(
						"CHECK_THIS_OUT_AUTH_TOKEN",
						data.verifyPhoneCode.token,
					);
					history.push("/organizations");
				} else {
					setError("There was an error. Please contact support.");
				}
			},
			onError(error) {
				setError(getErrorMessage(error));
				console.log(error);
			},
		},
	);

	// not 100% sure why organization return null on 1st render
	if (verifyLoading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}

	return (
		<Container
			onSubmit={(e) => {
				e.preventDefault();
				sendPhoneCode();
			}}
		>
			<Body>
				<LogoContainer>
					<LogoImg src={Logo} />
				</LogoContainer>
				<BodyText>
					Please enter your phone number to check your current balance!
				</BodyText>
				<PhoneNumberInput
					type="tel"
					placeholder="Enter your phone number"
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
						if (codeInputIsVisible) {
							setCodeInputIsVisible(false);
						}
						if (error) setError("");
					}}
					width="calc(100% - 35px)"
					required
				/>
				<BottomText>
					Don't have an account yet? You must be referred in order to login
					to Check This Out.
				</BottomText>
				{codeInputIsVisible && (
					<>
						<CodeText>
							We just sent a 4-digit code to your mobile phone, please
							enter it below.
						</CodeText>
						<CodeInputBoxes
							onComplete={(phoneCode) => {
								if (error) setError("");
								verifyPhoneCode({
									variables: {
										phoneNumber,
										phoneCode,
									},
								});
							}}
						/>
					</>
				)}
				{error && (
					<Error error={error} margin="20px 0px 0px 0px">
						{error}
					</Error>
				)}
			</Body>

			{!codeInputIsVisible && (
				<ButtonContainer>
					<Button
						margin="15px"
						text="CONFIRM PHONE NUMBER"
						width="calc(100% - 30px)"
						xsWidth="calc(100% - 30px)"
						loading={loading}
						alignSelf="center"
						display="flex"
						justify="center"
						xsHeight="40px"
					/>
				</ButtonContainer>
			)}
		</Container>
	);
}
