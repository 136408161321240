import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import colors from '../styles/colors';
import Button from './Button';
import BusinessPreview from './BusinessPreview';
import { OrganizationContext } from '../context/OrganizationContext';
import { AppContext } from '../context/AppContext';
import Loader from './Loader';

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.bg};
`;

const Header = styled.div`
  position: relative;
  background: red;
  width: 100%;
  height: fit-content;
  background: red;
  box-sizing: border-box;
`;

const Image = styled.div`
  background-image: ${(props) => `linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  height: 120px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${colors.grey1};
  line-height: 140%;
  margin-bottom: 15px;
`;

const BodyText = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 140%;
  color: ${colors.grey2};
`;

const Body = styled.div`
  flex: 1;
  margin: 40px 15px;
`;

const BusinessPreviewContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 15px;
  width: 100%;
`;

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

function InviteError() {
  const history = useHistory();
  const { organization } = useContext(OrganizationContext);
  const { app } = useContext(AppContext);

  // not 100% sure why organization returns null on 1st render
  if (!organization) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <OuterContainer>
      <Container>
        <Header>
          <Image src={organization.posterImageUrl} />
          <BusinessPreviewContainer>
            <BusinessPreview organization={organization} />
          </BusinessPreviewContainer>
        </Header>
        <Body>
          <HeaderText>
            There was an error
          </HeaderText>
          <BodyText>
            {app?.error || 'Please try again or contact support'}
          </BodyText>
        </Body>
        <Button
          margin="15px"
          text="Go back to organization list"
          width="calc(100% - 30px)"
          onClick={() => history.push('/organizations')}
        />
      </Container>
    </OuterContainer>
  );
}

export default InviteError;
