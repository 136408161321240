import React, { useMemo, useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from "react-router-dom";
import Layout from "./Layout";
import EmbedLayout from "./EmbedLayout";
import HomeLayout from "./HomeLayout";
import Home from "./Home";
import Invite from "./Invite";
import PhoneInput from "./PhoneInput";
import InviteFriends from "./InviteFriends";
import AcceptCoupon from "./AcceptCoupon";
import { OrganizationContext } from "../context/OrganizationContext";
import { AppContext } from "../context/AppContext";
import OrganizationList from "./OrganizationList";
import Unsubscribed from "./Unsubscribed";
import InviteError from "./InviteError";
import JoinReferral from "./JoinReferral";
import Discounts from "./Discounts";
import Embed from "./Embed";

export default function App() {
	const [organization, setOrganization] = useState(null);
	const [app, setApp] = useState(null);
	const appValue = useMemo(() => ({ app, setApp }), [app, setApp]);
	const orgValue = useMemo(
		() => ({ organization, setOrganization }),
		[organization, setOrganization],
	);
	useEffect(() => {
		// prevent android keyboard shrinking viewport when
		// open and affecting percentage based div heights for whole app
		// https://stackoverflow.com/questions/32963400/android-keyboard-shrinking-the-viewport-and-elements-using-unit-vh-in-css
		// const viewport = document.querySelector("meta[name=viewport]");
		// if (viewport) {
		//   viewport.setAttribute(
		//     'content',
		//     `height=${window.innerHeight}, width=${window.innerWidth}, initial-scale=1.0`,
		//   );
		// }
	}, []);

	return (
		<Router basename="/user">
			<Switch>
				<Route exact path="/">
					<Redirect to="/home" />
				</Route>
				<HomeLayout path="/home" component={Home} />
				<HomeLayout path="/organizations" component={OrganizationList} />
				<AppContext.Provider value={appValue}>
					<OrganizationContext.Provider value={orgValue}>
					  <Layout path="/embed" component={Embed} />
						<Layout path="/invite" component={Invite} />
						<Layout path="/join" component={JoinReferral} />
						<Layout path="/phoneInput" component={PhoneInput} />
						<Layout path="/acceptCoupon" component={AcceptCoupon} />
						<Layout path="/discounts" component={Discounts} />
						<Layout path="/inviteError" component={InviteError} />
						<Layout path="/inviteFriends" component={InviteFriends} />
						<Layout path="/unsubscribed" component={Unsubscribed} />
					</OrganizationContext.Provider>
				</AppContext.Provider>
				<Redirect to="/" />
			</Switch>
		</Router>
	);
}
