import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import colors from '../styles/colors';
import Loader from './Loader';
import { GET_ORGANIZATION_LIST } from '../graphql/queries';
import BalancePreview from './BalancePreview';
import Logo from '../assets/images/cto-full-logo.png';

const LoaderContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.form`
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const OrgBox = styled.div`
  width: 100%;
  height: 100px;
  background-image: ${(props) => `linear-gradient(180deg, rgba(0, 0, 0, 0) -400%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderText = styled.div`
  font-size: 1.4rem;
  color: ${colors.grey1};
  font-weight: 500;
  margin: 15px 0px;
`;

const InfoContainer = styled.div`
  margin-left: 15px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  background: ${colors.grey6};
`;

const LogoImg = styled.img`
  width: 120px;
`;

export default function OrganizationList() {
  const history = useHistory();
  const { data, loading } = useQuery(GET_ORGANIZATION_LIST);

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <LogoContainer>
        <LogoImg src={Logo} />
      </LogoContainer>
      <HeaderText>
        Your Discounts
      </HeaderText>
      {(() => {
        if (data?.getOrganizationList.length > 0) {
          return data.getOrganizationList.map((org, index) => {
            return (
              <OrgBox
                key={index}
                onClick={() => history.push(`/inviteFriends/?orgId=${org._id}`)}
                src={org.posterImageUrl}
              >
                <InfoContainer>
                  <BalancePreview organization={org} />
                </InfoContainer>
              </OrgBox>
            );
          });
        }
        return (
          <HeaderText>
            You are not part of any organizations
          </HeaderText>
        );
      })()}
    </Container>
  );
}
