import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import colors from "../styles/colors";
import { GET_ACTIVE_COUPONS } from "../graphql/queries";
import Loader from "./Loader";
import * as Price from "../util/Price";

const Logo = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin-right: 15px;
	border: 1px solid ${colors.grey5};
	background-color: white;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`;

const ImageContainer = styled.img`
	width: 100%;
	height: auto;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	position: relative;
`;

const InfoContainer = styled.div``;

const Name = styled.div`
	color: ${colors.white};
	font-weight: 600;
	font-size: 1.8rem;
`;

const Address = styled.div`
	color: ${colors.grey6};
	font-weight: 500;
	font-size: 1.4rem;
`;

const LoaderContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export default function BalancePreview({ organization }) {
	const { data, loading } = useQuery(GET_ACTIVE_COUPONS, {
		variables: {
			organizationId: organization._id,
		},
	});
	if (!organization) {
		return null;
	}

	if (loading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}

	return (
		<Container>
			<Logo>
				<ImageContainer src={organization.logoUrl} />
			</Logo>
			<InfoContainer>
				<Name>{organization.name}</Name>
				<Address>
					{`$${Price.output(
						data.activeCoupons.reduce(
							(acc, item) => (acc += item.amount),
							0,
						),
						true,
					)}`}
				</Address>
			</InfoContainer>
		</Container>
	);
}
