import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import ReactTooltip from "react-tooltip";
import colors from "../styles/colors";
import BusinessPreview from "./BusinessPreview";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import Icon from "./Icon";
import { IconEnum as Icons } from "./Icons";
import { GET_ACTIVE_COUPONS } from "../graphql/queries";
import CouponList from "./CouponsList";

const LoaderContainer = styled.div`
	min-height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Container = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	min-height: 100%;
	width: 100%;
	background: ${colors.bg};
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	/* min-height: 75vw; */
`;

const Header = styled.div`
	position: relative;
	background: red;
	width: 100%;
	height: fit-content;
	background: red;
	box-sizing: border-box;
`;

const InnerContainer = styled.div`s
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 100%;
  width: 100%;
  background: ${colors.bg};
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05); */
  max-height: 73vh;
  min-height: 73vh;
  @media screen and (max-width: 768px) {
    max-height: 70vh;
    min-height: 70vh;
  }
`;

const Image = styled.div`
	background-image: ${(props) =>
		`linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
	background-size: cover;
	background-position: center;
	background-origin: unset;
	height: 120px;
	width: 100%;
`;

const HeaderText = styled.div`
	text-align: center;
	font-size: ${(props) => props.fontSize || "1.8rem"};
	color: ${colors.grey1};
	margin: ${(props) => props.margin || "30px 0 0 15px"};
`;

const BodyText = styled.div`
	font-weight: 500;
	font-size: 1.4rem;
	color: ${colors.grey2};
`;

const BusinessPreviewContainer = styled.div`
	position: absolute;
	bottom: -25px;
	left: 15px;
	width: 100%;
`;

const BalanceContainer = styled.div`
	padding: 10px 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: ${colors.white};
	margin-bottom: 20px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	overflow-y: auto;
	max-height: 72vh;
`;

const BackButton = styled.div`
	background: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	color: ${colors.white};
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 15px;
	margin: 5px;
	cursor: pointer;
`;

const TopButtonContainer = styled.div`
	position: absolute;
	height: fit-content;
	width: 100%;
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const OuterContainer = styled.div`
	height: 100%;
	width: 100%;
`;

const ValidCouponContainer = styled.div`
	padding: 25px;
	text-align: center;
`;

// looks hacky but is a more browser compatible way of copying text
// as opposed to navigator.clipboard.writeText()

function Discounts() {
	const history = useHistory();
	const { organization } = useContext(OrganizationContext);
	// TODO: refactor and clean up
	const { data: coupData, loading: coupLoading } = useQuery(
		GET_ACTIVE_COUPONS,
		{
			variables: {
				organizationId: organization._id,
			},
		},
	);

	if (!organization || coupLoading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}

	return (
		<OuterContainer>
			<Container>
				<Header>
					<Image src={organization.posterImageUrl} />
					<TopButtonContainer>
						<BackButton
							onClick={() =>
								history.push(
									`/inviteFriends/?orgId=${organization._id}`,
								)
							}
						>
							<Icon
								icon={Icons.LeftArrow}
								activeColor={colors.white}
								active
								size={14}
								margin="0px 10px 0px 0px"
							/>
							<div>GO BACK</div>
						</BackButton>
						{/* <BackButton>
              <Icon
                icon={Icons.MobileSolid}
                activeColor={colors.white}
                active
                size={14}
                margin="0px 10px 0px 0px"
              />
              <div>{userData.getCurrentUser.phoneNumber}</div>
            </BackButton> */}
					</TopButtonContainer>
					<BusinessPreviewContainer>
						<BusinessPreview organization={organization} />
					</BusinessPreviewContainer>
				</Header>
				<InnerContainer>
					<HeaderText>Discounts</HeaderText>

					<BalanceContainer>
						{coupData && coupData.activeCoupons.length > 0 ? (
							<CouponList activeCoupons={coupData.activeCoupons} />
						) : (
							<ValidCouponContainer>
								<HeaderText fontSize="2.5rem" margin="30px 0 0 15px">
									Discount Not Found
								</HeaderText>
								<BodyText>
									Any discount associated with this phone number has
									either expired or does not exist. Start referring
									friends to earn discounts.
								</BodyText>
							</ValidCouponContainer>
						)}
					</BalanceContainer>
				</InnerContainer>
				<ReactTooltip place="bottom" type="dark" effect="solid" />
			</Container>
		</OuterContainer>
	);
}

export default Discounts;
