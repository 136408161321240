import React from 'react';
import styled from 'styled-components';
import colors from '../styles/colors';

const Logo = styled.div`
  height: 100px;
  width: 100px;
  min-width: 100px;
  border-radius: 50%;
  margin-right: 15px;
  border: 1px solid ${colors.grey5};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  position: relative;
  overflow: hidden;
  width: 100% !important;
`;

const InfoContainer = styled.div`
width: 100% !important;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    /* max-width: calc(100% - 145px); */
  }
`;

const Name = styled.div`
  color: ${colors.white};
  font-weight: 600;
  font-size: 1.8rem;
`;

/* const Logout = styled.div`
  color: ${colors.white};
  font-weight: 500;
  font-size: 1.6rem;
  margin-left: auto;
  display: inline-block;
  width:200px;
  cursor: pointer;
`;
 */
const Address = styled.div`
  color: ${colors.grey6};
  font-weight: 500;
  font-size: 1.4rem;
`;

export default function BusinessPreview({ organization }) {

  if (!organization) {
    return null;
  }

  const address = `${organization.address.city}, ${organization.address.state}`;
  return (
    <Container>
      <Logo>
        <ImageContainer src={organization.logoUrl} />
      </Logo>
      <InfoContainer>
        <Name>
          {organization.name}
        </Name>
        <Address>
          {address}
        </Address>
      </InfoContainer>
      {/* <Logout onClick={()=>{

        localStorage.removeItem("CHECK_THIS_OUT_AUTH_TOKEN");
        history.push('/home')
      }}>
          {localStorage.CHECK_THIS_OUT_AUTH_TOKEN ? 'Logout':'Login'}
        </Logout> */}
    </Container>
  );
}
