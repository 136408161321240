import React, { useState, /* useEffect */ } from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import * as Price from "../util/Price";
import moment from "moment";

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 450px) {
    padding: 10px;
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: ${(props) => props.a || "center"};
  width: 100%;
  font-size: 1.8rem;
  border-right: 1px solid ${colors.grey5};
  @media screen and (max-width: 450px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

/* const TableItem = styled.div`
  display: flex;
  font-size: 1.2rem;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.grey6};
  width: 100%;
  box-sizing: border-box;
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
  transition: all 0.2s;

  &:hover {
    background: ${(props) =>
      props.onClick ? "rgba(211, 211, 211, 0.1)" : null};
  }
`; */

const Row = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr;
  grid-template-rows: repeat(auto-fit, auto);
  background: ${(props) => props.background || colors.white};
  justify-content: center;
  position: relative;
  align-items: center;
  position: ${(props) => (props.sticky === "1" ? "sticky" : null)};
  top: ${(props) => (props.sticky === "1" ? "0" : null)};
  z-index: ${(props) => (props.sticky === "1" ? "5" : null)};

  @media (max-width: 450px) {
    grid-template-columns: 0.5fr 1fr 1fr;
  }
`;

const HeadText = styled.div`
  color: ${colors.grey1};
  font-size: 1.4rem;
  font-weight: 500;
  @media screen and (max-width: 450px) {
    font-size: 1.2rem;
  }
`;

const HeadingText = styled.div`
  display: flex;
  align-items: ${(props) => props.align || "center"};
  color: ${colors.grey3};
  font-size: 1.4rem;
  font-weight: 600;
  height: 40px;
  padding: 10px;
  justify-content: ${(props) => props.justify || "center"};
  width: 100%;
  @media screen and (max-width: 991px) {
    height: 30px;
  }
  @media screen and (max-width: 450px) {
    height: 30px;
    align-items: center;
    font-size: 1.2rem;
  }
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 40px;
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  border: 1px solid ${colors.grey5};
`;

const HeaderText = styled.div`
  font-size: 1.4rem;
  color: ${colors.grey3};
  @media screen and (max-width: 450px) {
    font-size: 1.2rem;
  }
`;


export default function CouponList({ activeCoupons }) {
//   const [coupons, setCoupons] = useState(activeCoupons || []);
  const [coupons] = useState(activeCoupons || []);

 /*  useEffect(() => {
    setCoupons(activeCoupons);
  }, []); */

  return (
    <Wrapper>
      <Heading justify="center">
        <HeadingText>
          Available Discount:{" "}
          {`$${Price.output(
            coupons.reduce((total, item) => total + parseInt(item.amount), 0),
            true
          )}`}
        </HeadingText>
      </Heading>
      <TableContainer>
        <Row background={colors.grey6} sticky="1">
          <Column justify="space-around">
            <HeaderText>#</HeaderText>
          </Column>
          <Column>
            <HeaderText>AMOUNT</HeaderText>
          </Column>
          <Column>
            <HeaderText>EXPIRATION DATE</HeaderText>
          </Column>
        </Row>

        {coupons.map((coupon, index) => {
          const expirationTime =
            coupon.redemptionExpiration === "Infinity"
              ? "Does Not Expire"
              : moment(coupon.redemptionExpiration * 1000).format("MM-DD-YYYY");
          return (
            <Row key={index}>
              <Column justify="space-around">
                <HeaderText>{index + 1}</HeaderText>
              </Column>
              <Column>
                <HeadText>{`$${Price.output(coupon.amount, true)}`}</HeadText>
              </Column>
              <Column>
                <HeadText>{expirationTime}</HeadText>
              </Column>
            </Row>
          );
        })}
      </TableContainer>
    </Wrapper>
  );
}
