import React, { useContext } from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import BusinessPreview from "./BusinessPreview";
import { OrganizationContext } from "../context/OrganizationContext";
import Loader from "./Loader";
import {useMobileMedia} from '../util/MediaQuery'

const LoaderContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: ${colors.bg};
  @media screen and (max-width: 768px) {
    min-height: 100vh;
  }
`;

const Header = styled.div`
  position: relative;
  background: red;
  width: 100%;
  height: fit-content;
  background: red;
  box-sizing: border-box;
`;

const Image = styled.div`
  background-image: ${(props) =>
    `linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-origin: unset;
  height: 120px;
  width: 100%;
`;

const HeaderText = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${colors.grey1};
  line-height: 140%;
  margin-bottom: 15px;
  text-align: center;
`;

const BodyText = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 140%;
  color: ${colors.grey2};
  text-align: center;

`;

const Body = styled.div`
  flex: 1;
  margin: 40px 15px;
`;

const BusinessPreviewContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 15px;
  width: 100%;
`;

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const TOS = styled.div`
  color: ${colors.grey3};
  margin: 25px 15px 10px 15px;
  text-align: center;
`;

const TOSLink = styled.a`
  color: ${colors.seaGreen};
`;

const JoinButton = styled.a`
      -webkit-appearance: none;
    cursor: pointer;
    border-radius: 5px;
    background: #27BBAD;
    width: calc(100%-30px) !important;
    padding: 5px 15px;
    margin: 0px 15px 0px 15px;
    display: flex;
    justify-content: center;
    justify-content: #FFFFFE;
    align-items: center;
    width: 60%;
    font-size: 1.4rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    height: 40px;
    align-self: center;
    color: white;
    font-weight: 600;
    -webkit-text-decoration: none;
    text-decoration: none;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	flex-direction: row;
	position: absolute;
	background-color: ${colors.white};
	bottom: 0px;
	min-height: 7px;
	bottom: 32px;
	align-items: center;
	max-width: 768px;

	@media screen and (max-width: 768px) {
		min-height: 70px;
		bottom: 0px;
		align-items: center;
	}
	@media screen and (max-width: 1024px) {
		min-height: 70px;
		bottom: 0px;
		align-items: center;
	}

	@media screen and (max-width: 450px) {
		flex-direction: column;
		align-items: center;
		bottom: 0px;
		background: ${colors.white};
		height: max-content;
	} ;
`;

/**
 * This function handles invites from both an organization
 * and a referrer
 */

const JOIN_REFERRAL_KEYWORD = 'SIGNUP';

function JoinReferral() {
  const { organization } = useContext(OrganizationContext);

  const isMobile = useMobileMedia()

  // not 100% sure why organization returns null on 1st render
  if (!organization) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
  return (
    <OuterContainer>
      <Container>
        <Header>
          <Image src={organization.posterImageUrl} />
          <BusinessPreviewContainer>
            <BusinessPreview organization={organization} />
          </BusinessPreviewContainer>
        </Header>
        <Body>
          <HeaderText>
            Join Referral Program
          </HeaderText>
          <BodyText>
            {isMobile ?"Tap the button below to join our referral program and then tap on send message button from you SMS application.":`To join our referral program send "${JOIN_REFERRAL_KEYWORD}" from your mobile to ${organization.phoneNumber}`}
          </BodyText>
        </Body>
        <TOS>
          By using Check This Out, you agree to the &nbsp;
          <TOSLink
            href="https://intercom.help/check-this-out/en/articles/4339634-check-this-out-terms-of-use-and-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use and Privacy Policy
          </TOSLink>
        </TOS>

       
      </Container>
      {isMobile&&
       <ButtonContainer> 
       <JoinButton
          margin="0px 15px 15px 15px"
          text="Join"
          width="calc(100% - 30px)"
          href={`sms:${organization?.phoneNumber}?&body=${JOIN_REFERRAL_KEYWORD}`}
        >
          Click here to text us!
        </JoinButton>
        </ButtonContainer>
        }
    </OuterContainer>
  );
}

export default JoinReferral;
