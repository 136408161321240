import React from "react";
import { darken } from "polished";
import styled from "styled-components";
import colors from "../styles/colors";
import Loader, { LoaderSizes } from "./Loader";

const ButtonContainer = styled.button`
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: ${(props) => props.radius || "5px"};
  background: ${(props) => props.background || colors.seaGreen};
  width: ${(props) => props.width || "150px"};
  margin: ${(props) => props.margin || "0"};
  color: ${(props) => props.textColor || colors.white};
  font-size: 1.4rem;
  transition: all 0.3s;
  padding: ${(props) => props.padding || "0 15px"};
  height: 50px;
  font-weight: 600;
  display: ${(props) => props.display || "flex"};
  align-items: center;
  align-self: ${(props) => props.alignSelf || null};
  justify-content: center;
  &:hover {
    background: ${(props) =>
      props.background
        ? darken("0.05", props.background)
        : darken("0.05", colors.seaGreen)};
  }
  @media (max-width: 450px) {
    width: ${(props) => props.xsWidth || "100%-30px"};
    min-height: ${(props) => props.xsHeight || "40px"};
    max-height: ${(props) => props.xsHeight || "40px"};
    margin: ${(props) => props.xsMargin || "0"};
  }
  @media (max-height: 480px) {
    /* width: ${(props) => props.xsWidth || "100%-30px"}; */
    min-height: ${(props) => props.xsHeight || "40px"};
    max-height: ${(props) => props.xsHeight || "40px"};
    margin: ${(props) => props.xsMargin || "0"};
  }
`;

export default function Button({
  text,
  onClick,
  margin,
  type,
  width,
  loading,
  background,
  xsWidth,
  alignSelf,
  display,
  justify,
  xsHeight,
  textColor,
  xsMargin,
  radius,
}) {
  return (
    <ButtonContainer
      background={background || null}
      type={type || null}
      onClick={onClick || null}
      margin={margin}
      width={width}
      xsWidth={xsWidth || null}
      alignSelf={alignSelf || null}
      display={display || null}
      justify={justify | null}
      xsHeight={xsHeight || null}
      textColor={textColor || null}
      xsMargin={xsMargin || margin}
      radius={radius}
    >
      {loading ? (
        <Loader color={colors.white} size={LoaderSizes.VerySmall} />
      ) : (
        text
      )}
    </ButtonContainer>
  );
}
