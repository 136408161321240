import React from "react";
import styled from "styled-components";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";
import colors from "../styles/colors";
import { Flex } from "./Flex";

const InputContainer = styled.div`
  display: flex;
  width: ${(props) => props.width};
  @media screen and (max-width: 450px) {
    width: ${(props) => props.xsWidth};
  }
  
`;

const InputStyle = styled.input`
  width: ${(props) => props.width};
  padding: 10px;
  border-radius: 5px;
  background: ${colors.white};
  border: 1px solid ${colors.grey5};
  height: 50px;
 
`;

const AddressContainer = styled.div`
  width: 400px;
  border-radius: 5px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const AddressInputStyle = styled.input`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${(props) => props.borderRadius};
  background: ${colors.white};
  border: 1px solid ${colors.grey5};
  outline: none;
`;

const PhoneInputStyle = styled(Cleave)`
  width: ${(props) => props.width || "100%"};
  padding: 0px 15px;
  height: 50px;
  border-radius: 5px;
  background: ${colors.white};
  border: 1px solid ${colors.grey5};
  line-height: 140%;
  font-size: 1.6rem;
  ::placeholder {
    color: ${(props) => props.placeholdercolor};
  }
  @media only screen and (min-width: 200px) and (max-width: 768px)  {
    height: ${(props) => props.xsheight || "50px"};
  }
`;

const HeaderStyle = styled.div`
  font-size: 1.2rem;
  color: ${colors.grey3};
  margin: ${(props) => (props.top ? "0px 0px 10px 0px" : "30px 0px 10px 0px")};
`;

const FooterStyle = styled.div`
  font-size: 1.2rem;
  color: ${colors.grey3};
`;

export default function Input({
  width,
  header,
  footer,
  type,
  placeholder,
  value,
  onChange,
  top,
  required,
  minLength,
}) {
  return (
    <InputContainer>
      {header && <HeaderStyle top={top}>{header}</HeaderStyle>}
      <InputStyle
        width={width || "380px"}
        type={type || "text"}
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={onChange || null}
        required={required}
        minLength={minLength}
      />
      {footer && <FooterStyle>{footer}</FooterStyle>}
    </InputContainer>
  );
}

export function AddressInput({
  header,
  footer,
  top,
  address1Value,
  address1OnChange,
  address2Value,
  address2OnChange,
  cityValue,
  cityOnChange,
  stateValue,
  stateOnChange,
  zipValue,
  zipOnChange,
}) {
  return (
    <InputContainer>
      {header && <HeaderStyle top={top}>{header}</HeaderStyle>}
      <AddressContainer>
        <AddressInputStyle
          type="text"
          placeholder="Address 1"
          value={address1Value}
          onChange={address1OnChange}
        />
        <AddressInputStyle
          type="text"
          placeholder="Address 2"
          value={address2Value}
          onChange={address2OnChange}
        />
        <Flex>
          <AddressInputStyle
            type="text"
            placeholder="City"
            value={cityValue}
            onChange={cityOnChange}
          />
          <AddressInputStyle
            type="text"
            placeholder="State"
            value={stateValue}
            onChange={stateOnChange}
            maxLength={2}
          />
          <AddressInputStyle
            type="text"
            placeholder="Zip"
            value={zipValue}
            onChange={zipOnChange}
          />
        </Flex>
      </AddressContainer>
      {footer && <FooterStyle>{footer}</FooterStyle>}
    </InputContainer>
  );
}

export function PhoneNumberInput({
  height,
  width,
  header,
  footer,
  type,
  placeholder,
  placeholderColor,
  value,
  onChange,
  top,
  required,
  xsWidth,
  xsHeight,
}) {
  return (
    <InputContainer
      height={height || "45px"}
      width={width || "380px"}
      xsWidth={xsWidth || "100%"}

    >
      {header && <HeaderStyle top={top}>{header}</HeaderStyle>}
      <PhoneInputStyle
        xsheight={xsHeight}
        type={type || "text"}
        placeholder={placeholder || ""}
        placeholdercolor={placeholderColor || ""}
        value={value || ""}
        onChange={onChange || null}
        required={required}
        options={{ phone: true, phoneRegionCode: "US" }}
        maxLength={14} // need to fix that weird edge case with multiple 1's at beginning of phone number
      />
      {footer && <FooterStyle>{footer}</FooterStyle>}
    </InputContainer>
  );
}

export function MoneyInput({
  height,
  width,
  header,
  footer,
  type,
  placeholder,
  value,
  onChange,
  top,
  required,
}) {
  return (
    <InputContainer>
      {header && <HeaderStyle top={top}>{header}</HeaderStyle>}
      <PhoneInputStyle
        height={height || "45px"}
        width={width || "380px"}
        type={type || "text"}
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={onChange || null}
        required={required}
        options={{ phone: true, phoneRegionCode: "US" }}
        maxLength={14}
      />
      {footer && <FooterStyle>{footer}</FooterStyle>}
    </InputContainer>
  );
}

export function TimeLimitDayInput({
  height,
  width,
  header,
  footer,
  type,
  placeholder,
  value,
  onChange,
  top,
  required,
}) {
  return (
    <InputContainer>
      {header && <HeaderStyle top={top}>{header}</HeaderStyle>}
      <PhoneInputStyle
        height={height || "45px"}
        width={width || "380px"}
        type={type || "text"}
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={onChange || null}
        required={required}
        options={{ phone: true, phoneRegionCode: "US" }}
        maxLength={14}
      />
      {footer && <FooterStyle>{footer}</FooterStyle>}
    </InputContainer>
  );
}
