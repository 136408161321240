import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

const apiHost = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:4444';
// const apiHost = 'http://192.168.1.4:4444';

// For local mobile development testing, you will have to change a couple things.
// set apiHost like the example below
// const apiHost = 'http://localipaddress:4444'; (ex: const apiHost = 'http://192.168.1.4:4444';)
// then you will have to allow cors to accept your request origin url in server.js like the example below
// app.use(cors({
//   origin: ['http://localhost:3000', 'http://localhost:3001', 'http://192.168.1.4:3000'],
//   credentials: true,
// }));
// Then, on the same wifi network, connect to http://192.168.1.4:3000 on your mobile device

/**
 * getHeaders for genrate dynamic headers
 * Fix for Incongito where localStorage throwing security error in chrome
 */
const getHeaders = () => {
  let authorization
  try {
    authorization = localStorage.getItem('CHECK_THIS_OUT_AUTH_TOKEN')
  } catch (error) {
    console.log(error)
  }
  if (authorization) {
    return { headers: { authorization } }
  }
  return { headers: {} }
}
export const authLink = new ApolloLink((operation, forward) => {
  const context = getHeaders()
  operation.setContext(context);
  return forward(operation);
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const httpLink = new HttpLink({
  uri: `${apiHost}/graphql`,
  credentials: 'same-origin',
});

export const omitTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && operation.operationName !== 'uploadFiles') {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map((data) => {
    return data;
  });
});

// helper functions
function omitDeep(obj, key) {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
}

function omitDeepArrayWalk(arr, key) {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    if (typeof val === 'object') return omitDeep(val, key);
    return val;
  });
}
